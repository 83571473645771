import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // language resources
  resources: {
    en: {
      translation: {
        preferences: "Preferences",
        changelg: "Change your language and currency",
        currency: "Currency",
        language: "Language",
        edit: "Edit",
        cancel: "Cancel",
        save: "Save",
        acc: "Account settings",
        acc_desc: "Manage your booking experience",
        acc_info: "Personal information",
        acc_info_upd: "Update your personal information",
        acc_info_mng: "Manage your personal information",
        acc_sec: "Security",
        acc_sec_adj: "Adjust your security settings",
        acc_sec_mng: "Manage security",
        acc_pref_mng: "Manage preferences",
        acc_res: "Reservations",
        acc_res_desc: "Manage your reservations",
        acc_res_no_desc: "You currently have no reservations",
        res_room_name: "Room Name",
        res_checkin: "Check In Date",
        res_checkout: "Check Out Date",
        err_username: "Username field required",
        err_username2: "Please enter a username that is not too long",
        err_name: "Name field required",
        err_email: "Please enter a valid email address",
        err_email2: "The email has already been taken",
        err_num: "Please enter a valid phone number",
        err_dob: "Invalid date of birth",
        pro_name: "Display name",
        pro_username: "Username",
        pro_email: "Email",
        pro_num: "Phone Number",
        pro_dob: "Date of birth",
        pro_gender: "Gender",
        female: "Female",
        male: "Male",
        pro_password: "Password",
        pro_send_email: "Send Email",
        reset: "Reset",
        account: "Account",
        delete: "Delete",
        logout: "Log out",
        login: "Log in",
        home: "Home",
        rooms: "Rooms",
        findus: "Find Us",
        contact: "Contact",
        services: "Services",
        discover: "Discover",
        room_service_t: "Round-the-Clock Room Service",
        coffee_t: "Coffee Oasis",
        wellness_t: "Wellness Retreat",
        beer_t: "strongest beers",
        room_service:
          "Experience unparalleled convenience with our 24/7 room service at Matar Residence. Indulge in gourmet meals, snacks, or refreshing beverages delivered straight to your doorstep, ensuring that you can savor delicious culinary delights at any hour. Whether it's a late-night craving or an early morning treat, our attentive staff is always ready to cater to your culinary desires.",
        coffee:
          "Fuel your day with creativity and productivity at our 24/7 available coffee spot, the Coffee Oasis. Perfect for guests seeking a cozy atmosphere to work or unwind, this dedicated space offers a selection of premium coffee blends, teas, and snacks. Enjoy complimentary Wi-Fi, ergonomic seating, and a tranquil ambiance, making it an ideal setting for casual meetings, solo work sessions, or simply sipping your favorite brew in style.",
        wellness:
          "Immerse yourself in tranquility at our Wellness Retreat, a sanctuary designed for relaxation and rejuvenation. Unwind with a range of wellness services, from invigorating spa treatments to calming yoga sessions. Our fitness center, equipped with state-of-the-art facilities, ensures that guests can maintain their health and well-being throughout their stay.",
        beer: "Beer lovers rejoice! Our resort boasts some of the strongest beers around, perfect for enjoying after a day of adventure. With a wide selection of craft beers, you're sure to find a new favorite.",
        home_w: "Welcome To Our Hotel",
        room_w: "Our Rooms",
        home_feat: "Featured room",
        home_feat2: "Deal of the month",
        reviews: "Reviews",
        explore: "Explore",
        followus: "Follow Us",
        aboutus: "About Us",
        privacypolicy: "Privacy Policy",
        faq: "FAQs",
        allrights: "All Rights Reserved",
        breakfast_t: "BREAKFAST",
        groupoffers_t: "GROUP OFFERS",
        parking_t: "PARKING",
        promotions_t: "PROMOTIONS",
        services_w: "Discover our exclusive services",
        promotions:
          "At our establishment, we provide a variety of vouchers and promotions that cater to your needs. You have the opportunity to save money and enjoy our services depending on the season.",
        parking:
          "To find a parking space in Vienna is very tedious and expensive. Thanks to our location there is a garage opposite the pension. Parking tickets can be purchased at the reception from just €18 per day.",
        groupoffers:
          "Whether you're traveling with a school group, a professional delegation, or for a wedding, we have customized group packages to fit your needs. Contact us to learn more about our tailored offers for groups.",
        breakfast:
          "Begin your day with a delicious and nutritious breakfast, freshly prepared daily. We serve a buffet in our breakfast room from 07:30 to 10:00. If you prefer a 'breakfast to go', our staff will gladly prepare it for you.",
        submit_1: "Thank you for booking at our hotel",
        submit_2: "You will be redirected to the home page shortly",
        pagenotfound: "The page you’re looking for doesn’t exist.",
        goback: "Go back",
        err_subject: "Subject too long",
        err_message: "Message too long",
        contact_w: "Contact us to book",
        con_email: "Email Address",
        con_fax: "Fax Address",
        con_location: "Location",
        con_send: "Send Message",
        con_send_w:
          "We're excited to welcome you to our hotel! Reach out to us to learn more about our accommodations and bookings.",
        con_name: "Name",
        con_subject: "Subject",
        con_message: "Message",
        discover_w1: "RAISING COMFOMRT TO THE HIGHEST LEVEL",
        discover_w2: "Discover the Hotel",
        discover_p:
          "Indulge in the epitome of luxury at Matar Residence, where modern sophistication meets warm hospitality. Nestled in the heart of Beirut, our hotel offers a refined retreat, featuring thoughtfully appointed rooms and suites with plush amenities, a culinary journey at our on-site restaurant, and relaxation in our exclusive spa. Conveniently located near [popular landmarks, business districts, or attractions], we provide easy access to the city's vibrant culture. From personalized concierge services to versatile event spaces, every detail reflects our commitment to excellence. Immerse yourself in the ultimate blend of comfort and style—book your stay at Matar Residence for an unforgettable experience.",
        discover_p2:
          "Linger in our breakfast room and enjoy a rich buffet breakfast before setting out to explore the city from our fabulous location.",
        photogallery: "Photo Gallery",
        findus_title1: "Schönbrunn Palace",
        findus_title2: "The Hofburg",
        findus_title3: "Belvedere Palace",
        findus_title4: "St. Stephen's Cathedral",
        findus_title5: "Vienna Operahouse",
        findus_desc1:
          "Schönbrunn Palace is a Baroque palace in Vienna, Austria built as a summer residence for the Habsburgs. It has over 1,400 rooms, luxurious furnishings, and intricate decorations showcasing Habsburg opulence. The palace gardens feature lawns, flower beds, fountains, and landmarks such as the Neptune Fountain and Gloriette. It is a popular tourist attraction and a UNESCO World Heritage Site.",
        findus_desc2:
          "The Hofburg is a large palace complex in the center of Vienna, Austria. It has served as the residence of the Habsburg dynasty and the Austrian government for over 700 years. The palace features impressive Baroque and Gothic architecture and is home to several museums, including the Imperial Apartments, the Sisi Museum, and the Austrian National Library. Today, it is one of Vienna's most popular tourist attractions, offering a glimpse into Austria's rich history and culture.",
        findus_desc3:
          "Belvedere Palace is a Baroque-style building complex in Vienna, Austria, built as a summer residence for Prince Eugene of Savoy in the 18th century. It consists of the Upper and Lower Belvedere palaces, connected by a beautiful garden. Today, it is a popular tourist attraction and art museum, displaying works by famous Austrian artists such as Gustav Klimt and Egon Schiele.",
        findus_desc4:
          "St. Stephen's Cathedral, or Stephansdom, is a Gothic-style cathedral in Vienna, Austria. Its iconic spire stands at 136 meters tall, and it boasts intricate stained glass windows, vaulted ceilings, and ornate altarpieces. The cathedral has a rich history as a religious and cultural landmark and remains a popular destination for visitors today.",
        findus_desc5:
          "The Vienna Opera House is a world-famous opera house located in Vienna, Austria. It opened in 1869 and is home to the Vienna State Opera and Vienna Philharmonic Orchestra. The Neo-Renaissance building has a seating capacity of over 2,200 and is known for its ornate architecture.",
        findus_arr1: "S-Bahn (S7)",
        findus_arr2: "CAT - City Airport Train",
        findus_arr3: "Vienna Airport Lines (Postbus ÖBB)",
        findus_arr4: "TAXI",
        findus_arrd1:
          "The S-Bahn offers the cheapest fare to the airport. Several stopovers allow easy entry and exit across the city, most of them with suburban and subway connections. Trains leave between about 04.00 and 00.00. A ride takes about 23 minutes from Wien Mitte and costs 4.10 €",
        findus_arrd2:
          "The City Airport Train is perhaps the most comfortable travel option, but also one of the most expensive. Every 30 minutes there is a fast train between Wien-Mitte and the airport. From / to: Wien-Mitte (U3, U4 + S) - Flughafen Wien, Bahnhof Cost: when buying online 11 € (single), 19 € (return); when buying at machines 12 € (single), 21 € (return) Interval: 30 minutes",
        findus_arrd3:
          "The Vienna Airport Lines connect important transport hubs with the airport. And most bus stops are directly connected to subway stations. Buses from Morzinplatz / Schwedenplatz and Westbahnhof leave every 30 minutes. Cost: 8 € (single), 13 € (return) Interval: 30 minutes or 1 hour (line VAL3) Duration: 20-40 minutes",
        findus_arrd4:
          "The Vienna taxi centers offer trips with an airport package. For a fixed price of 36 € (if ordered in advance) you get so directly from home to the airport. Accessible for example under 31300, 40100 or 60160. Please say on the phone that you need an airport taxi! From / to: anywhere in Vienna - Airport, departure / arrival Cost: 36 € fixed price (+ tip)  Duration: varies depending on place of departure and traffic Duration: 20-40 minutes",
        arrival: "Arrival",
        find_location_desc: `Our house is an ideal starting point to discover the beauty of Vienna. Thanks to our central location, all sights are within easy reach: We are only 10 minutes walk from the train station "Westbahnhof" and 20 minutes walk from the city center. Our address is Andreasgasse 1, 1070 Vienna, near the famous shopping street "Mariahilfer Straße". With the subway station "U3 Zieglergasse" directly in front of our house is a drive to cultural attractions or shopping just a few minutes away.`,
        bookroom: "Book your room",
        searchroom: "Search rooms",
        roomtype: "room type",
        guests: "guests",
        roomprice: "room price",
        breakfast_r: "breakfast",
        pets: "pets",
        learnmore: "Learn More",
        workspace: "Work Space",
        minibar: "Minibar",
        towels: "Towels",
        shower: "Shower",
        tv: "TV",
        wifi: "WI-Fi",
        size: "Size",
        sqm: "sqm",
        occupancy: "Occupancy",
        no_rooms: "unfortunately no rooms matched your search parameters",
        price: "PRICE",
        booknow: "BOOK NOW",
        freewifi: "FREE WIFI",
        amenities: "Amenities",
        oldprice: "OLD PRICE",
        newprice: "NEW PRICE",
        pricing: "Pricing",
        description: "Description",
        beds2: "BEDS",
        floors: "FLOORS",
        guests2: "GUESTS",
        squarem2: "SQUARE M2",
        inttv: "INTERNATIONAL TV CHANNELS",
        showerava: "SHOWER AVAILABLE",
        minibarava: "MINI BAR AVAILABLE",
        deskava: "DESK AVAILABLE",
        breakava: "BREAKFAST AVAILABLE",
        petsava: "PETS ALLOWED",
        err_special: "Special request message too long",
        guestdetails: "Guest Details",
        country: "Country",
        firstname: "First Name",
        lastname: "Last Name",
        completing:
          "By completing this booking I acknowledge I have read and accepted the",
        continue: "Continue to Check-out",
        specialrequest: "Special Request",
        order: "Order Summary",
        beds: "Beds",
        guest: "Guests",
        subtotal: "Subtotal",
        tax: "Tax",
        total: "Total",
        save_changes: "Save Changes",
        main_head: "Maintenance Request",
        send_request: "Send Request",
        submit_main: "Submitting Maintenance Request",
        submit_main1: "Maintenance Request Submitted",
        feedback_submit1:
          "Thank you for taking the time to share your feedback with us!",
        feedback_submit2:
          "We appreciate your input and will take it into consideration as we work to improve our services.",
        feedback_head: "Feedback",
        feedback_w:
          "We would be thrilled if you could share your thoughts on our website. Your feedback is incredibly valuable to us and helps us improve our services.",
      },
    },
    es: {
      translation: {
        preferences: "Preferencias",
        changelg: "Cambia tu idioma y moneda",
        currency: "Divisa",
        language: "Idioma",
        edit: "Editar",
        cancel: "Cancelar",
        save: "Guardar",
        acc: "Configuración de cuenta",
        acc_desc: "Administra tu experiencia de reservación",
        acc_info: "Información personal",
        acc_info_upd: "Actualiza tu información personal",
        acc_info_mng: "Administra tu información personal",
        acc_sec: "Seguridad",
        acc_sec_adj: "Ajusta tu configuración de seguridad",
        acc_sec_mng: "Administra la seguridad",
        acc_pref_mng: "Administra las preferencias",
        acc_res: "Reservaciones",
        acc_res_desc: "Administra tus reservaciones",
        acc_res_no_desc: "Actualmente no tienes reservaciones",
        res_room_name: "Nombre de la habitación",
        res_checkin: "Fecha de llegada",
        res_checkout: "Fecha de salida",
        err_username: "Se requiere un nombre de usuario",
        err_username2:
          "Ingrese un nombre de usuario que no sea demasiado largo",
        err_name: "Se requiere un nombre",
        err_email: "Ingrese una dirección de correo electrónico válida",
        err_email2: "El correo electrónico ya ha sido registrado",
        err_num: "Ingrese un número de teléfono válido",
        err_dob: "Fecha de nacimiento inválida",
        pro_name: "Nombre de visualización",
        pro_username: "Nombre de usuario",
        pro_email: "Correo electrónico",
        pro_num: "Número de teléfono",
        pro_dob: "Fecha de nacimiento",
        pro_gender: "Género",
        female: "Femenino",
        male: "Masculino",
        pro_password: "Contraseña",
        pro_send_email: "Enviar correo electrónico",
        reset: "Restablecer",
        account: "Cuenta",
        delete: "Eliminar",
        logout: "Cerrar sesión",
        login: "Iniciar sesión",
        home: "Inicio",
        rooms: "Habitaciones",
        findus: "Encuéntranos",
        contact: "Contacto",
        services: "Servicios",
        discover: "Descubrir",
        room_service_t: "Servicio de habitaciones las 24 horas",
        coffee_t: "Oasis de café",
        wellness_t: "Retiro de bienestar",
        beer_t: "cervezas más fuertes",
        room_service:
          "Experimente una comodidad incomparable con nuestro servicio de habitaciones 24 horas al día, 7 días a la semana en Matar Residence. Deléitese con comidas gourmet, refrigerios o bebidas refrescantes entregados directamente en la puerta de su casa, lo que le permitirá saborear deliciosas delicias culinarias a cualquier hora. Ya sea que se trate de un antojo nocturno o de un capricho temprano en la mañana, nuestro atento personal siempre está listo para satisfacer sus deseos culinarios.",
        coffee:
          "Alimente su día con creatividad y productividad en nuestra cafetería disponible las 24 horas, los 7 días de la semana, Coffee Oasis. Perfecto para huéspedes que buscan un ambiente acogedor para trabajar o relajarse, este espacio exclusivo ofrece una selección de mezclas de café, tés y refrigerios de primera calidad. Disfrute de Wi-Fi gratuito, asientos ergonómicos y un ambiente tranquilo, lo que lo convierte en un entorno ideal para reuniones informales, sesiones de trabajo en solitario o simplemente para disfrutar de su cerveza favorita con estilo.",
        wellness:
          "Sumérgete en la tranquilidad en nuestro Wellness Retreat, un santuario diseñado para la relajación y el rejuvenecimiento. Relájese con una variedad de servicios de bienestar, desde vigorizantes tratamientos de spa hasta relajantes sesiones de yoga. Nuestro gimnasio, equipado con instalaciones de última generación, garantiza que los huéspedes puedan mantener su salud y bienestar durante toda su estancia.",
        beer: "¡Los amantes de la cerveza pueden regocijarse! Nuestro resort cuenta con algunas de las cervezas más fuertes, perfectas para disfrutar después de un día de aventuras. Con una amplia selección de cervezas artesanales, seguramente encontrará una nueva favorita.",
        home_w: "Bienvenidos a nuestro hotel",
        room_w: "Nuestras habitaciones",
        home_feat: "Habitación destacada",
        home_feat2: "Oferta del mes",
        reviews: "Reseñas",
        explore: "Explorar",
        followus: "Síguenos",
        aboutus: "Sobre nosotros",
        privacypolicy: "Política de privacidad",
        faq: "Preguntas frecuentes",
        allrights: "Todos Los Derechos Reservados",
        breakfast_t: "DESAYUNO",
        groupoffers_t: "OFERTAS PARA GRUPOS",
        parking_t: "ESTACIONAMIENTO",
        promotions_t: "PROMOCIONES",
        services_w: "Descubre nuestros exclusivos servicios",
        promotions:
          "En nuestro establecimiento, ofrecemos una variedad de cupones y promociones que se adaptan a tus necesidades. Tienes la oportunidad de ahorrar dinero y disfrutar de nuestros servicios dependiendo de la temporada.",
        parking:
          "Encontrar un lugar para estacionar en Viena es muy tedioso y costoso. Gracias a nuestra ubicación, hay un garaje frente a la pensión. Los tickets de estacionamiento se pueden comprar en la recepción a partir de solo €18 por día.",
        groupoffers:
          "Ya sea que viajes con un grupo escolar, una delegación profesional o para una boda, tenemos paquetes de grupos personalizados para satisfacer tus necesidades. Contáctanos para conocer más sobre nuestras ofertas hechas a medida para grupos.",
        breakfast:
          "Comienza tu día con un desayuno delicioso y nutritivo, recién preparado todos los días. Servimos un buffet en nuestra sala de desayunos de 07:30 a 10:00. Si prefieres un 'desayuno para llevar', nuestro personal lo preparará con mucho gusto para ti.",
        submit_1: "Gracias por reservar en nuestro hotel",
        submit_2: "En breve serás redirigido a la página principal",
        pagenotfound: "La página que buscas no existe.",
        goback: "Volver",
        err_subject: "Asunto demasiado largo",
        err_message: "Mensaje demasiado largo",
        contact_w: "Contáctanos para reservar",
        con_email: "Correo electrónico",
        con_fax: "Número de fax",
        con_location: "Ubicación",
        con_send: "Enviar mensaje",
        con_send_w:
          "¡Estamos emocionados de darle la bienvenida a nuestro hotel! Contáctanos para obtener más información sobre nuestras habitaciones y reservas.",
        con_name: "Nombre",
        con_subject: "Asunto",
        con_message: "Mensaje",
        err_subject: "Asunto demasiado largo",
        err_message: "Mensaje demasiado largo",
        contact_w: "Contáctanos para reservar",
        con_email: "Correo electrónico",
        con_fax: "Número de fax",
        con_location: "Ubicación",
        con_send: "Enviar mensaje",
        con_send_w:
          "¡Estamos emocionados de darle la bienvenida a nuestro hotel! Contáctanos para obtener más información sobre nuestras habitaciones y reservas.",
        con_name: "Nombre",
        con_subject: "Asunto",
        con_message: "Mensaje",
        discover_w1: "ELEVANDO LA COMODIDAD AL NIVEL MÁS ALTO",
        discover_w2: "Descubre el hotel",
        discover_p:
          "Nuestra pensión se encuentra en una típica casa de estilo Wilhelminiano en la calle Mariahilferstrasse. La pensión se extiende sobre 3 plantas y ofrece 30 habitaciones y 2 apartamentos con diferentes categorías de habitaciones y precios. Cada una de nuestras habitaciones es diferente pero está amueblada con atención al detalle. Colchones de alta calidad y muebles individuales nos identifican.",
        discover_p2:
          "Permanezca en nuestra sala de desayunos y disfrute de un rico desayuno buffet antes de salir a explorar la ciudad desde nuestra fabulosa ubicación.",
        photogallery: "Galería de fotos",
        findus_title1: "Palacio de Schönbrunn",
        findus_title2: "El Hofburg",
        findus_title3: "Palacio de Belvedere",
        findus_title4: "Catedral de San Esteban",
        findus_title5: "Ópera de Viena",
        findus_desc1:
          "El Palacio de Schönbrunn es un palacio barroco en Viena, Austria, construido como residencia de verano de los Habsburgo. Tiene más de 1,400 habitaciones, lujosos muebles y decoraciones intrincadas que muestran la opulencia de los Habsburgo. Los jardines del palacio cuentan con césped, parterres, fuentes y monumentos como la Fuente de Neptuno y la Glorieta. Es una atracción turística popular y un sitio del Patrimonio Mundial de la UNESCO.",
        findus_desc2:
          "El Hofburg es un gran complejo palaciego en el centro de Viena, Austria. Ha servido como residencia de la dinastía de los Habsburgo y del gobierno austriaco durante más de 700 años. El palacio cuenta con impresionante arquitectura barroca y gótica y alberga varios museos, como los Apartamentos Imperiales, el Museo Sisi y la Biblioteca Nacional de Austria. Hoy en día, es una de las atracciones turísticas más populares de Viena, ofreciendo una visión de la rica historia y cultura de Austria.",
        findus_desc3:
          "El Palacio de Belvedere es un complejo de edificios de estilo barroco en Viena, Austria, construido como residencia de verano del príncipe Eugenio de Saboya en el siglo XVIII. Consta de los palacios Belvedere Superior e Inferior, conectados por un hermoso jardín. Hoy en día, es una popular atracción turística y museo de arte, que muestra obras de famosos artistas austriacos como Gustav Klimt y Egon Schiele.",
        findus_desc4:
          "La Catedral de San Esteban, o Stephansdom, es una catedral de estilo gótico en Viena, Austria. Su icónica aguja mide 136 metros de altura, y cuenta con intrincados vitrales, techos abovedados y retablos ornamentados. La catedral tiene una rica historia como hito religioso y cultural y sigue siendo un destino popular para visitantes en la actualidad.",
        findus_desc5:
          "La Ópera de Viena es una famosa casa de ópera ubicada en Viena, Austria. Abrió en 1869 y es el hogar de la Ópera Estatal de Viena y la Orquesta Filarmónica de Viena. El edificio neo-renacentista tiene una capacidad de más de 2,200 asientos y es conocido por su arquitectura ornamentada.",
        findus_arr1: "S-Bahn (S7)",
        findus_arr2: "CAT - Tren del aeropuerto de la ciudad",
        findus_arr3: "Vienna Airport Lines (Postbus ÖBB)",
        findus_arr4: "TAXI",
        findus_arrd1:
          "El S-Bahn ofrece la tarifa más económica para llegar al aeropuerto. Varias paradas permiten un fácil acceso y salida a través de la ciudad, la mayoría de ellas con conexiones suburbanas y de metro. Los trenes salen entre las 04.00 y las 00.00 horas. Un viaje dura unos 23 minutos desde Wien Mitte y cuesta 4,10 €.",
        findus_arrd2:
          "El Tren del Aeropuerto de la Ciudad es quizás la opción de viaje más cómoda, pero también una de las más caras. Cada 30 minutos hay un tren rápido entre Wien-Mitte y el aeropuerto. Desde/hasta: Wien-Mitte (U3, U4 + S) - Flughafen Wien, Bahnhof Costo: al comprar en línea 11 € (sencillo), 19 € (ida y vuelta); al comprar en las máquinas 12 € (sencillo), 21 € (ida y vuelta) Intervalo: 30 minutos",
        findus_arrd3:
          "Las Líneas del Aeropuerto de Viena conectan importantes centros de transporte con el aeropuerto. La mayoría de las paradas de autobús están directamente conectadas con estaciones de metro. Los autobuses desde Morzinplatz / Schwedenplatz y Westbahnhof salen cada 30 minutos. Costo: 8 € (sencillo), 13 € (ida y vuelta) Intervalo: 30 minutos o 1 hora (línea VAL3) Duración: 20-40 minutos",
        findus_arrd4:
          "Los centros de taxis de Viena ofrecen viajes con paquete de aeropuerto. Por un precio fijo de 36 € (si se solicita con anticipación) puede llegar directamente desde su hogar hasta el aeropuerto. Accesible por ejemplo bajo 31300, 40100 o 60160. ¡Por favor, diga por teléfono que necesita un taxi al aeropuerto! Desde / hasta: cualquier lugar de Viena - Aeropuerto, salida / llegada Costo: precio fijo de 36 € (+ propina) Duración: varía según el lugar de salida y el tráfico Duración: 20-40 minutos",
        arrival: "Llegada",
        find_location_desc: `Nuestra casa es un punto de partida ideal para descubrir la belleza de Viena. Gracias a nuestra ubicación central, todas las atracciones están al alcance de la mano: estamos a solo 10 minutos a pie de la estación de tren "Westbahnhof" y a 20 minutos a pie del centro de la ciudad. Nuestra dirección es Andreasgasse 1, 1070 Viena, cerca de la famosa calle comercial "Mariahilfer Straße". Con la estación de metro "U3 Zieglergasse" justo enfrente de nuestra casa, un viaje a atracciones culturales o de compras está a solo unos minutos de distancia.`,
        bookroom: "Reserva tu habitación",
        searchroom: "Buscar habitaciones",
        roomtype: "tipo de habitación",
        guests: "huéspedes",
        roomprice: "precio de habitación",
        breakfast_r: "desayuno",
        pets: "mascotas",
        learnmore: "Más información",
        workspace: "Espacio de trabajo",
        minibar: "Minibar",
        towels: "Toallas",
        shower: "Ducha",
        tv: "TV",
        wifi: "WI-Fi",
        size: "Tamaño",
        sqm: "m²",
        occupancy: "Ocupación",
        no_rooms:
          "Lo sentimos, no hay habitaciones que coincidan con los parámetros de búsqueda.",
        price: "PRECIO",
        booknow: "RESERVAR AHORA",
        freewifi: "WIFI GRATIS",
        amenities: "Servicios",
        oldprice: "PRECIO ANTERIOR",
        newprice: "PRECIO NUEVO",
        pricing: "Precios",
        description: "Descripción",
        beds2: "CAMAS",
        floors: "PISOS",
        guests2: "HUESPEDES",
        squarem2: "M2 CUADRADOS",
        inttv: "CANALES DE TV INTERNACIONALES",
        showerava: "DUCHA DISPONIBLE",
        minibarava: "MINIBAR DISPONIBLE",
        deskava: "ESCRITORIO DISPONIBLE",
        breakava: "DESAYUNO DISPONIBLE",
        petsava: "SE ADMITEN MASCOTAS",
        err_special: "Mensaje de solicitud especial demasiado largo",
        guestdetails: "Detalles del huésped",
        country: "País",
        firstname: "Nombre",
        lastname: "Apellido",
        completing:
          "Al completar esta reserva, reconozco que he leído y aceptado los",
        continue: "Continuar con el check-out",
        specialrequest: "Solicitud especial",
        order: "Resumen del pedido",
        beds: "Camas",
        guest: "Huéspedes",
        subtotal: "Subtotal",
        tax: "Impuesto",
        total: "Total",
        save_changes: "Guardar",
        main_head: "Solicitud de Mantenimiento",
        send_request: "Enviar Solicitud",
        submit_main: "Enviando Solicitud de Mantenimiento",
        submit_main1: "Solicitud de Mantenimiento Enviada",
        feedback_submit1:
          "¡Gracias por tomarte el tiempo de compartir tu opinión con nosotros!",
        feedback_submit2:
          "Apreciamos tus comentarios y los tendremos en cuenta mientras trabajamos para mejorar nuestros servicios.",

        feedback_head: "Comentarios",
        feedback_w:
          "Nos encantaría que compartiera sus pensamientos sobre nuestro sitio web. Sus comentarios son extremadamente valiosos para nosotros y nos ayudan a mejorar nuestros servicios.",
      },
    },
    fr: {
      translation: {
        preferences: "Préférences",
        changelg: "Changez votre langue et votre devise",
        currency: "Monnaie",
        language: "Langue",
        edit: "Modifier",
        cancel: "Annuler",
        save: "Sauvegarder",
        acc: "Paramètres du compte",
        acc_desc: "Gérez votre expérience de réservation",
        acc_info: "Informations personnelles",
        acc_info_upd: "Mettez à jour vos informations personnelles",
        acc_info_mng: "Gérez vos informations personnelles",
        acc_sec: "Sécurité",
        acc_sec_adj: "Ajustez vos paramètres de sécurité",
        acc_sec_mng: "Gérez la sécurité",
        acc_pref_mng: "Gérez les préférences",
        acc_res: "Réservations",
        acc_res_desc: "Gérez vos réservations",
        acc_res_no_desc: "Vous n'avez actuellement aucune réservation",
        res_room_name: "Nom de la Salle",
        res_checkin: "Date d'arrivée",
        res_checkout: "Date de départ",
        err_username: "Le champ du nom d'utilisateur est requis",
        err_username2:
          "Veuillez entrer un nom d'utilisateur qui n'est pas trop long",
        err_name: "Le champ du nom est requis",
        err_email: "Veuillez entrer une adresse e-mail valide",
        err_email2: "L'adresse e-mail a déjà été prise",
        err_num: "Veuillez entrer un numéro de téléphone valide",
        err_dob: "Date de naissance invalide",
        pro_name: "Nom d'affichage",
        pro_username: "Nom d'utilisateur",
        pro_email: "E-mail",
        pro_num: "Numéro de téléphone",
        pro_dob: "Date de naissance",
        pro_gender: "Genre",
        female: "Femme",
        male: "Homme",
        pro_password: "Mot de passe",
        pro_send_email: "Envoyer un e-mail",
        reset: "Réinitialiser",
        account: "Compte",
        delete: "Supprimer",
        logout: "Déconnexion",
        login: "Connexion",
        home: "Accueil",
        rooms: "Chambres",
        findus: "Nous trouver",
        contact: "Contact",
        services: "Services",
        discover: "Découvrir",
        room_service_t: "Service de chambre 24 heures sur 24",
        coffee_t: "Oasis de café",
        wellness_t: "Retraite bien-être",
        beer_t: "bières les plus fortes",
        room_service:
          "Faites l'expérience d'un confort inégalé grâce à notre service d'étage 24h/24 et 7j/7 à la résidence Matar. Offrez-vous des repas gastronomiques, des collations ou des boissons rafraîchissantes livrés directement à votre porte, garantissant ainsi de pouvoir savourer de délicieux délices culinaires à toute heure. Qu'il s'agisse d'une envie de fin de soirée ou d'une gâterie tôt le matin, notre personnel attentionné est toujours prêt à répondre à vos envies culinaires.",
        coffee:
          "Alimentez votre journée avec créativité et productivité dans notre café disponible 24h/24 et 7j/7, le Coffee Oasis. Idéal pour les clients recherchant une atmosphère chaleureuse pour travailler ou se détendre, cet espace dédié propose une sélection de mélanges de café, de thés et de collations haut de gamme. Profitez d'une connexion Wi-Fi gratuite, de sièges ergonomiques et d'une ambiance tranquille, ce qui en fait un cadre idéal pour des réunions informelles, des séances de travail en solo ou simplement pour siroter votre bière préférée avec style.",
        wellness:
          "Plongez dans la tranquillité de notre retraite bien-être, un sanctuaire conçu pour la détente et le rajeunissement. Détendez-vous avec une gamme de services de bien-être, allant des soins de spa revigorants aux séances de yoga apaisantes. Notre centre de remise en forme, équipé d'installations de pointe, veille à ce que les clients puissent maintenir leur santé et leur bien-être tout au long de leur séjour.",
        beer: "Les amateurs de bière se réjouiront ! Notre complexe propose certaines des bières les plus fortes, parfaites pour se détendre après une journée d'aventures. Avec une large sélection de bières artisanales, vous êtes sûr de trouver une nouvelle préférée.",
        home_w: "Bienvenue dans notre hôtel",
        room_w: "Nos chambres",
        home_feat: "Chambre en vedette",
        home_feat2: "Offre du mois",
        reviews: "Avis",
        explore: "Explorer",
        followus: "Suivez-nous",
        aboutus: "À propos de nous",
        privacypolicy: "Politique de confidentialité",
        faq: "FAQ",
        allrights: "Tous Droits Réservés",
        breakfast_t: "PETIT-DÉJEUNER",
        groupoffers_t: "OFFRES DE GROUPE",
        parking_t: "STATIONNEMENT",
        promotions_t: "PROMOTIONS",
        services_w: "Découvrez nos services exclusifs",
        promotions:
          "Dans notre établissement, nous proposons une variété de bons et de promotions qui répondent à vos besoins. Vous avez la possibilité d'économiser de l'argent et de profiter de nos services en fonction de la saison.",
        parking:
          "Trouver une place de parking à Vienne est très fastidieux et coûteux. Grâce à notre emplacement, il y a un garage en face de la pension. Les tickets de parking peuvent être achetés à la réception à partir de seulement €18 par jour.",
        groupoffers:
          "Que vous voyagiez avec un groupe scolaire, une délégation professionnelle ou pour un mariage, nous avons des forfaits de groupe personnalisés pour répondre à vos besoins. Contactez-nous pour en savoir plus sur nos offres sur mesure pour les groupes.",
        breakfast:
          "Commencez votre journée avec un petit-déjeuner délicieux et nutritif, fraîchement préparé tous les jours. Nous servons un buffet dans notre salle de petit-déjeuner de 07h30 à 10h00. Si vous préférez un 'petit-déjeuner à emporter', notre personnel se fera un plaisir de le préparer pour vous.",
        submit_1: "Merci d'avoir réservé dans notre hôtel",
        submit_2: "Vous serez bientôt redirigé vers la page d'accueil",
        pagenotfound: "La page que vous recherchez n'existe pas.",
        goback: "Retourner",
        err_subject: "Sujet trop long",
        err_message: "Message trop long",
        contact_w: "Contactez-nous pour réserver",
        con_email: "Adresse e-mail",
        con_fax: "Adresse fax",
        con_location: "Emplacement",
        con_send: "Envoyer le message",
        con_send_w:
          "Nous sommes ravis de vous accueillir dans notre hôtel ! Contactez-nous pour en savoir plus sur nos chambres et nos réservations.",
        con_name: "Nom",
        con_subject: "Sujet",
        con_message: "Message",
        discover_w1: "ÉLÉVATION DU CONFORT AU PLUS HAUT NIVEAU",
        discover_w2: "Découvrez l'hôtel",
        discover_p:
          "Notre pension est située dans une maison typique de style wilhelmien sur la Mariahilferstrasse. La maison d'hôtes s'étend sur 3 étages et propose 30 chambres et 2 appartements avec différentes catégories de chambres et de prix. Chacune de nos chambres est différente mais meublée avec une grande attention aux détails. Des matelas de haute qualité et des meubles individuels nous identifient.",
        discover_p2:
          "Restez dans notre salle de petit-déjeuner et profitez d'un délicieux petit-déjeuner buffet avant de partir explorer la ville depuis notre fabuleux emplacement.",
        photogallery: "Galerie de photos",
        findus_title1: "Palais de Schönbrunn",
        findus_title2: "Le Hofburg",
        findus_title3: "Palais du Belvédère",
        findus_title4: "La cathédrale Saint-Étienne",
        findus_title5: "Opéra de Vienne",
        findus_desc1:
          "Le palais de Schönbrunn est un palais baroque situé à Vienne, en Autriche, construit comme résidence d'été pour les Habsbourg. Il possède plus de 1 400 pièces, un mobilier luxueux et des décorations complexes mettant en valeur l'opulence des Habsbourg. Les jardins du palais comportent des pelouses, des parterres de fleurs, des fontaines et des monuments tels que la fontaine de Neptune et la Gloriette. Il s'agit d'une attraction touristique populaire et d'un site du patrimoine mondial de l'UNESCO.",
        findus_desc2:
          "Le Hofburg est un grand complexe de palais situé au centre de Vienne, en Autriche. Il a servi de résidence à la dynastie des Habsbourg et au gouvernement autrichien pendant plus de 700 ans. Le palais présente une architecture baroque et gothique impressionnante et abrite plusieurs musées, dont les appartements impériaux, le musée Sisi et la Bibliothèque nationale autrichienne. Aujourd'hui, c'est l'une des attractions touristiques les plus populaires de Vienne, offrant un aperçu de l'histoire et de la culture riches de l'Autriche.",
        findus_desc3:
          "Le palais du Belvédère est un complexe de bâtiments de style baroque à Vienne, en Autriche, construit comme résidence d'été pour le prince Eugène de Savoie au XVIIIe siècle. Il se compose des palais du Belvédère supérieur et inférieur, reliés par un beau jardin. Aujourd'hui, c'est une attraction touristique populaire et un musée d'art, exposant des œuvres d'artistes autrichiens célèbres tels que Gustav Klimt et Egon Schiele.",
        findus_desc4:
          "La cathédrale Saint-Étienne, ou Stephansdom, est une cathédrale gothique située à Vienne, en Autriche. Son icône flèche s'élève à 136 mètres de haut, et elle possède des vitraux complexes, des plafonds voûtés et des retables ornés. La cathédrale a une riche histoire en tant que lieu de culte et de repère culturel et reste une destination populaire pour les visiteurs aujourd'hui.",
        findus_desc5:
          "L'Opéra de Vienne est un opéra de renommée mondiale situé à Vienne, en Autriche. Il a ouvert ses portes en 1869 et abrite l'Opéra national de Vienne et l'Orchestre philharmonique de Vienne. Le bâtiment de style néo-Renaissance a une capacité d'assise de plus de 2 200 personnes et est connu pour son architecture ornée.",
        findus_arr1: "S-Bahn (S7)",
        findus_arr2: "CAT - City Airport Train",
        findus_arr3: "Vienna Airport Lines (Postbus ÖBB)",
        findus_arr4: "TAXI",
        findus_arrd1:
          "Le S-Bahn offre le tarif le moins cher pour l'aéroport. Plusieurs arrêts permettent une entrée et une sortie faciles dans toute la ville, la plupart d'entre eux étant connectés aux banlieues et au métro. Les trains circulent entre environ 04h00 et 00h00. Le trajet prend environ 23 minutes depuis Wien Mitte et coûte 4,10 €.",
        findus_arrd2:
          "Le City Airport Train est peut-être l'option de voyage la plus confortable, mais aussi l'une des plus chères. Toutes les 30 minutes, un train rapide relie Wien-Mitte à l'aéroport. De / à: Wien-Mitte (U3, U4 + S) - Flughafen Wien, Bahnhof Coût: 11 € en ligne (aller simple), 19 € (aller-retour) ; 12 € aux distributeurs (aller simple), 21 € (aller-retour) Fréquence: toutes les 30 minutes",
        findus_arrd3:
          "Les Vienna Airport Lines connectent les centres de transport importants à l'aéroport. Et la plupart des arrêts de bus sont directement reliés aux stations de métro. Les bus de Morzinplatz/Schwedenplatz et de Westbahnhof partent toutes les 30 minutes. Coût : 8 € (aller simple), 13 € (aller-retour) Fréquence : 30 minutes ou 1 heure (ligne VAL3) Durée : 20-40 minutes",
        findus_arrd4:
          "Les centres de taxi de Vienne offrent des trajets avec un forfait aéroportuaire. Pour un prix fixe de 36 € (si commandé à l'avance), vous pouvez vous rendre directement de chez vous à l'aéroport. Accessible par exemple sous les numéros 31300, 40100 ou 60160. Veuillez dire au téléphone que vous avez besoin d'un taxi pour l'aéroport ! De / à : partout à Vienne - Aéroport, départ / arrivée Coût : 36 € prix fixe (+ pourboire) Durée : varie selon le lieu de départ et le trafic Durée : 20-40 minutes",
        arrival: "Arrivée",
        find_location_desc: `Notre maison est un point de départ idéal pour découvrir la beauté de Vienne. Grâce à notre emplacement central, tous les sites touristiques sont facilement accessibles : nous ne sommes qu'à 10 minutes à pied de la gare "Westbahnhof" et à 20 minutes à pied du centre-ville. Notre adresse est Andreasgasse 1, 1070 Vienne, près de la célèbre rue commerçante "Mariahilfer Straße". Avec la station de métro "U3 Zieglergasse" directement devant notre maison, il ne faut que quelques minutes pour se rendre aux attractions culturelles ou faire du shopping.`,
        bookroom: "Réservez votre chambre",
        searchroom: "Rechercher des chambres",
        roomtype: "type de chambre",
        guests: "invités",
        roomprice: "prix de chambre",
        breakfast_r: "petit-déjeuner",
        pets: "animaux domestiques",
        learnmore: "Savoir Plus",
        workspace: "Espace de travail",
        minibar: "Minibar",
        towels: "Serviettes",
        shower: "Douche",
        tv: "TV",
        wifi: "WI-Fi",
        size: "Taille",
        sqm: "m²",
        occupancy: "Occupation",
        no_rooms:
          "Malheureusement, aucune chambre ne correspond à vos critères de recherche.",
        price: "PRIX",
        booknow: "RÉSERVER",
        freewifi: "WIFI GRATUIT",
        amenities: "Équipements",
        oldprice: "ANCIEN PRIX",
        newprice: "NOUVEAU PRIX",
        pricing: "Tarification",
        description: "Description",
        beds2: "LITS",
        floors: "ÉTAGES",
        guests2: "HÔTES",
        squarem2: "M2 CARRÉS",
        inttv: "CHAÎNES TV INTERNATIONALES",
        showerava: "DOUCHE DISPONIBLE",
        minibarava: "MINI-BAR DISPONIBLE",
        deskava: "BUREAU DISPONIBLE",
        breakava: "PETIT-DÉJEUNER DISPONIBLE",
        petsava: "ANIMAUX DOMESTIQUES AUTORISÉS",
        err_special: "Message de demande spéciale trop long",
        guestdetails: "Détails de l'invité",
        country: "Pays",
        firstname: "Prénom",
        lastname: "Nom de famille",
        completing:
          "En complétant cette réservation, j'accepte d'avoir lu et accepté les",
        continue: "Continuer vers la caisse",
        specialrequest: "Demande spéciale",
        order: "Résumé de la commande",
        beds: "Lits",
        guest: "Invités",
        subtotal: "Sous-total",
        tax: "Taxe",
        total: "Total",

        save_changes: "Sauveguarder",
        main_head: "Demande de Maintenance",
        send_request: "Envoyer Demande",
        submit_main: "Envoi de la Demande de Maintenance",
        submit_main1: "Demande de Maintenance Envoyée",
        feedback_submit1:
          "Merci d'avoir pris le temps de nous faire part de votre avis !",
        feedback_submit2:
          "Nous apprécions vos commentaires et les prendrons en considération pour améliorer nos services.",
        feedback_head: "Commentaires",
        feedback_w:
          "Nous serions ravis si vous pouviez partager vos réflexions sur notre site web. Vos commentaires sont incroyablement précieux pour nous et nous aident à améliorer nos services.",
      },
    },
    de: {
      translation: {
        preferences: "Einstellungen",
        changelg: "Ändern Sie Ihre Sprache und Währung",
        currency: "Währung",
        language: "Sprache",
        edit: "Bearbeiten",
        cancel: "Stornieren",
        save: "Speichern",
        acc: "Kontoeinstellungen",
        acc_desc: "Verwalten Sie Ihr Buchungserlebnis",
        acc_info: "Persönliche Informationen",
        acc_info_upd: "Aktualisieren Sie Ihre persönlichen Informationen",
        acc_info_mng: "Verwalten Sie Ihre persönlichen Informationen",
        acc_sec: "Sicherheit",
        acc_sec_adj: "Passen Sie Ihre Sicherheitseinstellungen an",
        acc_sec_mng: "Sicherheit verwalten",
        acc_pref_mng: "Einstellungen verwalten",
        acc_res: "Reservierungen",
        acc_res_desc: "Verwalten Sie Ihre Reservierungen",
        acc_res_no_desc: "Sie haben derzeit keine Reservierungen",
        res_room_name: "Zimmername",
        res_checkin: "Check-in Datum",
        res_checkout: "Check-out Datum",
        err_username: "Benutzername erforderlich",
        err_username2:
          "Bitte geben Sie einen Benutzernamen ein, der nicht zu lang ist",
        err_name: "Name Feld erforderlich",
        err_email: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        err_email2: "Die E-Mail-Adresse ist bereits vergeben",
        err_num: "Bitte geben Sie eine gültige Telefonnummer ein",
        err_dob: "Ungültiges Geburtsdatum",
        pro_name: "Anzeigename",
        pro_username: "Benutzername",
        pro_email: "E-Mail",
        pro_num: "Telefonnummer",
        pro_dob: "Geburtsdatum",
        pro_gender: "Geschlecht",
        female: "Weiblich",
        male: "Männlich",
        pro_password: "Passwort",
        pro_send_email: "E-Mail senden",
        reset: "Zurücksetzen",
        account: "Konto",
        delete: "Löschen",
        logout: "Ausloggen",
        login: "Einloggen",
        home: "Startseite",
        rooms: "Zimmer",
        findus: "Finden Sie uns",
        contact: "Kontakt",
        services: "Dienstleistungen",
        discover: "Entdecken",
        room_service_t: "Zimmerservice rund um die Uhr",
        coffee_t: "Kaffeeoase",
        wellness_t: "Wellness-Retreat",
        beer_t: "Stärkste Biere",
        room_service:
          "Erleben Sie unvergleichlichen Komfort mit unserem Zimmerservice rund um die Uhr in der Matar Residence. Lassen Sie sich mit Gourmetgerichten, Snacks oder erfrischenden Getränken verwöhnen, die direkt an Ihre Haustür geliefert werden, sodass Sie rund um die Uhr köstliche kulinarische Köstlichkeiten genießen können. Ganz gleich, ob es sich um einen Heißhunger am späten Abend oder einen Leckerbissen am frühen Morgen handelt, unser aufmerksames Personal ist immer bereit, auf Ihre kulinarischen Wünsche einzugehen.",
        coffee:
          "Beleben Sie Ihren Tag mit Kreativität und Produktivität in unserem rund um die Uhr verfügbaren Kaffee-Spot, der Coffee Oasis. Dieser spezielle Bereich ist perfekt für Gäste, die eine gemütliche Atmosphäre zum Arbeiten oder Entspannen suchen und bietet eine Auswahl an erstklassigen Kaffeemischungen, Tees und Snacks. Genießen Sie kostenloses WLAN, ergonomische Sitzgelegenheiten und eine ruhige Atmosphäre, was es zu einem idealen Rahmen für ungezwungene Meetings, Solo-Arbeitssitzungen oder einfach nur für den stilvollen Genuss Ihres Lieblingsgetränks macht.",
        wellness:
          "Tauchen Sie ein in die Ruhe in unserem Wellness Retreat, einem Zufluchtsort für Entspannung und Erholung. Entspannen Sie sich bei einer Reihe von Wellnessangeboten, von belebenden Spa-Behandlungen bis hin zu beruhigenden Yoga-Sitzungen. Unser mit modernsten Einrichtungen ausgestattetes Fitnesscenter sorgt dafür, dass die Gäste während ihres gesamten Aufenthalts ihre Gesundheit und ihr Wohlbefinden bewahren können.",
        beer: "Bierliebhaber aufgepasst! Unser Resort bietet einige der stärksten Biere der Umgebung, perfekt zum Genießen nach einem Tag voller Abenteuer. Mit einer großen Auswahl an Craft-Bieren finden Sie bestimmt einen neuen Favoriten.",
        home_w: "Willkommen in unserem Hotel",
        room_w: "Unsere Zimmer",
        home_feat: "Vorgestelltes Zimmer",
        home_feat2: "Angebot des Monats",
        reviews: "Bewertungen",
        explore: "Erkunden",
        followus: "Folge uns",
        aboutus: "Über uns",
        privacypolicy: "Datenschutzrichtlinie",
        faq: "FAQs",
        allrights: "Alle Rechte vorbehalten",
        breakfast_t: "FRÜHSTÜCK",
        groupoffers_t: "GRUPPENANGEBOTE",
        parking_t: "PARKPLATZ",
        promotions_t: "PROMOTIONEN",
        services_w: "Entdecken Sie unsere exklusiven Services",
        promotions:
          "In unserer Einrichtung bieten wir eine Vielzahl von Gutscheinen und Promotionen, die auf Ihre Bedürfnisse zugeschnitten sind. Je nach Saison haben Sie die Möglichkeit, Geld zu sparen und unsere Services zu genießen.",
        parking:
          "Das Finden eines Parkplatzes in Wien ist sehr mühsam und teuer. Dank unserer Lage gibt es gegenüber der Pension eine Garage. Parktickets können an der Rezeption ab 18 € pro Tag erworben werden.",
        groupoffers:
          "Ob Sie mit einer Schulgruppe, einer professionellen Delegation oder für eine Hochzeit reisen - wir haben maßgeschneiderte Gruppenpakete, die auf Ihre Bedürfnisse zugeschnitten sind. Kontaktieren Sie uns, um mehr über unsere auf Gruppen zugeschnittenen Angebote zu erfahren.",
        breakfast:
          "Beginnen Sie Ihren Tag mit einem köstlichen und nahrhaften Frühstück, das täglich frisch zubereitet wird. Wir servieren ein Buffet in unserem Frühstücksraum von 07:30 bis 10:00 Uhr. Wenn Sie ein 'Frühstück zum Mitnehmen' bevorzugen, bereitet unser Personal es Ihnen gerne zu.",
        submit_1: "Vielen Dank für Ihre Buchung in unserem Hotel",
        submit_2: "Sie werden in Kürze zur Startseite weitergeleitet",
        pagenotfound: "Die von Ihnen gesuchte Seite existiert nicht.",
        goback: "Zurück",
        err_subject: "Betreff zu lang",
        err_message: "Nachricht zu lang",
        contact_w: "Kontaktieren Sie uns, um zu buchen",
        con_email: "E-Mail-Adresse",
        con_fax: "Fax-Adresse",
        con_location: "Standort",
        con_send: "Nachricht senden",
        con_send_w:
          "Wir freuen uns, Sie in unserem Hotel begrüßen zu dürfen! Kontaktieren Sie uns, um mehr über unsere Zimmer und Buchungen zu erfahren.",
        con_name: "Name",
        con_subject: "Betreff",
        con_message: "Nachricht",
        discover_w1: "KOMFORT AUF HÖCHSTEM NIVEAU",
        discover_w2: "Entdecken Sie das Hotel",
        discover_p:
          "Unsere Pension befindet sich in einem typischen Wilhelminischen Stilhaus an der Mariahilferstraße. Das Gästehaus erstreckt sich über 3 Etagen und bietet 30 Zimmer und 2 Apartments mit unterschiedlichen Zimmer- und Preis-Kategorien. Jedes unserer Zimmer ist unterschiedlich, aber mit Liebe zum Detail eingerichtet. Hochwertige Matratzen und individuelle Möbel zeichnen uns aus.",
        discover_p2:
          "Verweilen Sie in unserem Frühstücksraum und genießen Sie ein reichhaltiges Frühstücksbuffet, bevor Sie von unserem fabelhaften Standort aus die Stadt erkunden.",
        photogallery: "Fotogalerie",
        findus_title1: "Schloss Schönbrunn",
        findus_title2: "Die Hofburg",
        findus_title3: "Schloss Belvedere",
        findus_title4: "Stephansdom",
        findus_title5: "Wiener Opernhaus",
        findus_desc1:
          "Schloss Schönbrunn ist ein barockes Schloss in Wien, Österreich, das als Sommerresidenz für die Habsburger erbaut wurde. Es hat über 1.400 Zimmer, luxuriöse Ausstattung und aufwändige Dekorationen, die den Habsburger Prunk zur Schau stellen. Die Schlossgärten umfassen Rasenflächen, Blumenbeete, Springbrunnen und Wahrzeichen wie den Neptunbrunnen und die Gloriette. Es ist eine beliebte Touristenattraktion und ein UNESCO-Weltkulturerbe.",
        findus_desc2:
          "Die Hofburg ist eine große Palastanlage im Zentrum von Wien, Österreich. Sie diente als Residenz der Habsburger Dynastie und der österreichischen Regierung für über 700 Jahre. Der Palast besticht durch beeindruckende barocke und gotische Architektur und beherbergt mehrere Museen, darunter die Kaiserappartements, das Sisi-Museum und die Österreichische Nationalbibliothek. Heute ist es eine der beliebtesten Touristenattraktionen Wiens und bietet einen Einblick in die reiche Geschichte und Kultur Österreichs.",
        findus_desc3:
          "Das Schloss Belvedere ist ein barockes Gebäudeensemble in Wien, Österreich, das im 18. Jahrhundert als Sommerresidenz für Prinz Eugen von Savoyen erbaut wurde. Es besteht aus den Oberen und Unteren Belvedere-Schlössern, die von einem wunderschönen Garten verbunden sind. Heute ist es eine beliebte Touristenattraktion und Kunstmuseum, das Werke berühmter österreichischer Künstler wie Gustav Klimt und Egon Schiele ausstellt.",
        findus_desc4:
          "Der Stephansdom ist eine gotische Kathedrale in Wien, Österreich. Sein markanter Turm ragt 136 Meter hoch auf und er verfügt über kunstvoll gestaltete Bleiglasfenster, Gewölbedecken und Altarbilder. Die Kathedrale hat eine reiche Geschichte als religiöses und kulturelles Wahrzeichen und ist auch heute noch ein beliebtes Reiseziel für Besucher.",
        findus_desc5:
          "Das Wiener Opernhaus ist ein weltberühmtes Opernhaus in Wien, Österreich. Es wurde 1869 eröffnet und beherbergt die Wiener Staatsoper und das Wiener Philharmonische Orchester. Das neorenaissance Gebäude hat eine Sitzkapazität von über 2.200 und ist für seine prächtige Architektur bekannt.",
        findus_arr1: "S-Bahn (S7)",
        findus_arr2: "CAT - City Airport Train",
        findus_arr3: "Vienna Airport Lines (Postbus ÖBB)",
        findus_arr4: "TAXI",
        findus_arrd1:
          "Die S-Bahn bietet den günstigsten Fahrpreis zum Flughafen. Mehrere Haltestellen ermöglichen einen einfachen Einstieg und Ausstieg in der ganzen Stadt, die meisten davon mit Anschlüssen an S-Bahn und U-Bahn. Züge fahren zwischen ca. 04:00 und 00:00 Uhr. Eine Fahrt dauert etwa 23 Minuten von Wien Mitte und kostet 4,10 €.",
        findus_arrd2:
          "Der City Airport Train ist vielleicht die komfortabelste Reisemöglichkeit, aber auch eine der teuersten. Alle 30 Minuten fährt ein Schnellzug zwischen Wien-Mitte und dem Flughafen. Von / nach: Wien-Mitte (U3, U4 + S) - Flughafen Wien, Bahnhof. Kosten: beim Online-Kauf 11 € (einfache Fahrt), 19 € (Rückfahrt); beim Kauf an den Automaten 12 € (einfache Fahrt), 21 € (Rückfahrt). Intervall: 30 Minuten.",
        findus_arrd3:
          "Die Vienna Airport Lines verbinden wichtige Verkehrsknotenpunkte mit dem Flughafen. Und die meisten Bushaltestellen sind direkt mit U-Bahn-Stationen verbunden. Busse vom Morzinplatz / Schwedenplatz und Westbahnhof fahren alle 30 Minuten. Kosten: 8 € (einfache Fahrt), 13 € (Rückfahrt). Intervall: 30 Minuten oder 1 Stunde (Linie VAL3). Dauer: 20-40 Minuten.",
        findus_arrd4:
          "Die Wiener Taxizentralen bieten Fahrten mit einem Flughafenpaket an. Für einen Festpreis von 36 € (bei Vorbestellung) gelangen Sie so direkt von zu Hause zum Flughafen. Erreichbar z.B. unter 31300, 40100 oder 60160. Bitte sagen Sie am Telefon, dass Sie ein Flughafentaxi benötigen! Von / nach: überall in Wien - Flughafen, Abfahrt / Ankunft. Kosten: 36 € Festpreis (+ Trinkgeld). Dauer: variiert je nach Abfahrtsort und Verkehr. Dauer: 20-40 Minuten.",
        arrival: "Ankunft",
        find_location_desc: `Unser Haus ist ein idealer Ausgangspunkt, um die Schönheit von Wien zu entdecken. Dank unserer zentralen Lage sind alle Sehenswürdigkeiten leicht zu erreichen: Wir sind nur 10 Minuten zu Fuß vom Bahnhof "Westbahnhof" und 20 Minuten zu Fuß vom Stadtzentrum entfernt. Unsere Adresse lautet Andreasgasse 1, 1070 Wien, in der Nähe der berühmten Einkaufsstraße "Mariahilfer Straße". Mit der U-Bahn-Station "U3 Zieglergasse" direkt vor unserem Haus sind kulturelle Attraktionen oder Einkaufsmöglichkeiten nur wenige Minuten entfernt.`,
        bookroom: "Buchen Sie Ihr Zimmer",
        searchroom: "Zimmer suchen",
        roomtype: "Zimmertyp",
        guests: "Gäste",
        roomprice: "Zimmerpreis",
        breakfast_r: "Frühstück",
        pets: "Haustiere",
        learnmore: "Mehr erfahren",
        workspace: "Arbeitsplatz",
        minibar: "Minibar",
        towels: "Handtücher",
        shower: "Dusche",
        tv: "TV",
        wifi: "WI-Fi",
        size: "Größe",
        sqm: "m²",
        occupancy: "Belegung",
        no_rooms: "Leider entspricht keine Zimmer den Suchparametern.",
        price: "PREIS",
        booknow: "JETZT BUCHEN",
        freewifi: "KOSTENLOSES WLAN",
        amenities: "Annehmlichkeiten",
        oldprice: "ALTER PREIS",
        newprice: "NEUER PREIS",
        pricing: "Preisgestaltung",
        description: "Beschreibung",
        beds2: "BETTEN",
        floors: "ETAGEN",
        guests2: "GÄSTE",
        squarem2: "QUADRATMETER",
        inttv: "INTERNATIONALE TV-SENDER",
        showerava: "DUSCHE VERFÜGBAR",
        minibarava: "MINIBAR VERFÜGBAR",
        deskava: "SCHREIBTISCH VERFÜGBAR",
        breakava: "FRÜHSTÜCK VERFÜGBAR",
        petsava: "HAUSTIERE ERLAUBT",
        err_special: "Spezialanfrage zu lang",
        guestdetails: "Gastdetails",
        country: "Land",
        firstname: "Vorname",
        lastname: "Nachname",
        completing:
          "Durch Abschluss dieser Buchung erkenne ich an, dass ich die",
        continue: "Weiter zum Check-out",
        specialrequest: "Spezialanfrage",
        order: "Bestellübersicht",
        beds: "Betten",
        guest: "Gäste",
        subtotal: "Zwischensumme",
        tax: "Steuer",
        total: "Gesamt",
        save_changes: "Änderungen",
        main_head: "Wartungsanfrage",
        send_request: "Anfrage senden",
        submit_main: "Wartungsanfrage wird gesendet",
        submit_main1: "Wartungsanfrage gesendet",
        feedback_submit1:
          "Vielen Dank, dass Sie sich die Zeit genommen haben, uns Ihr Feedback mitzuteilen!",
        feedback_submit2:
          "Wir schätzen Ihre Meinung und werden sie berücksichtigen, während wir daran arbeiten, unsere Dienstleistungen zu verbessern.",
        feedback_head: "Feedback",
        feedback_w:
          "Wir würden uns freuen, wenn Sie uns Ihre Gedanken zu unserer Website mitteilen könnten. Ihr Feedback ist unglaublich wertvoll für uns und hilft uns, unsere Dienstleistungen zu verbessern.",
      },
    },
    it: {
      translation: {
        preferences: "Preferenze",
        changelg: "Cambia lingua e valuta",
        currency: "Valuta",
        language: "Lingua",
        edit: "Modificare",
        cancel: "Annulla",
        save: "Salva",
        acc: "Impostazioni dell'account",
        acc_desc: "Gestisci la tua esperienza di prenotazione",
        acc_info: "Informazioni personali",
        acc_info_upd: "Aggiorna le tue informazioni personali",
        acc_info_mng: "Gestisci le tue informazioni personali",
        acc_sec: "Sicurezza",
        acc_sec_adj: "Regola le tue impostazioni di sicurezza",
        acc_sec_mng: "Gestisci la sicurezza",
        acc_pref_mng: "Gestisci le preferenze",
        acc_res: "Prenotazioni",
        acc_res_desc: "Gestisci le tue prenotazioni",
        acc_res_no_desc: "Attualmente non hai prenotazioni",
        res_room_name: "Nome della stanza",
        res_checkin: "Data di check-in",
        res_checkout: "Data di check-out",
        err_username: "Campo nome utente richiesto",
        err_username2: "Inserisci un nome utente che non sia troppo lungo",
        err_name: "Campo nome richiesto",
        err_email: "Inserisci un indirizzo email valido",
        err_email2: "L'email è già stata utilizzata",
        err_num: "Inserisci un numero di telefono valido",
        err_dob: "Data di nascita non valida",
        pro_name: "Nome visualizzato",
        pro_username: "Nome utente",
        pro_email: "Email",
        pro_num: "Numero di telefono",
        pro_dob: "Data di nascita",
        pro_gender: "Genere",
        female: "Femmina",
        male: "Maschio",
        pro_password: "Password",
        pro_send_email: "Invia e-mail",
        reset: "Reset",
        account: "Account",
        delete: "Elimina",
        logout: "Esci",
        login: "Accedi",
        home: "Home",
        rooms: "Stanze",
        findus: "Trova noi",
        contact: "Contatto",
        services: "Servizi",
        discover: "Scoprire",
        room_service_t: "Servizio in camera 24 ore su 24",
        coffee_t: "Oasi del caffè",
        wellness_t: "Ritiro di benessere",
        beer_t: "Birre più forti",
        room_service:
          "Sperimenta una comodità senza precedenti con il nostro servizio in camera 24 ore su 24, 7 giorni su 7 al Matar Residence. Concedetevi pasti gourmet, snack o bevande rinfrescanti consegnati direttamente a casa vostra, assicurandovi di poter assaporare deliziose delizie culinarie a qualsiasi ora. Che si tratti di una voglia a tarda notte o di un dolcetto mattutino, il nostro personale attento è sempre pronto a soddisfare i tuoi desideri culinari.",
        coffee:
          "Alimenta la tua giornata con creatività e produttività nel nostro bar disponibile 24 ore su 24, 7 giorni su 7, Coffee Oasis. Perfetto per gli ospiti che cercano un'atmosfera accogliente per lavorare o rilassarsi, questo spazio dedicato offre una selezione di miscele di caffè, tè e snack di prima qualità. Goditi la connessione Wi-Fi gratuita, i posti a sedere ergonomici e un ambiente tranquillo, che lo rendono un ambiente ideale per riunioni informali, sessioni di lavoro da soli o semplicemente sorseggiando la tua birra preferita con stile.",
        wellness:
          "Immergiti nella tranquillità del nostro Wellness Retreat, un santuario progettato per il relax e il ringiovanimento. Rilassatevi con una gamma di servizi benessere, dai trattamenti termali tonificanti alle sessioni di yoga rilassanti. Il nostro centro fitness, dotato di strutture all'avanguardia, garantisce che gli ospiti possano mantenere la propria salute e il proprio benessere durante tutto il soggiorno.",
        beer: "Amanti della birra, rallegratevi! Il nostro resort vanta alcune delle birre più forti della zona, perfette da gustare dopo una giornata di avventura. Con una vasta selezione di birre artigianali, troverai sicuramente un nuovo preferito.",
        home_w: "Benvenuti al nostro hotel",
        room_w: "Le nostre camere",
        home_feat: "Camera in evidenza",
        home_feat2: "Offerta del mese",
        reviews: "Recensioni",
        explore: "Esplora",
        followus: "Seguici",
        aboutus: "Chi siamo",
        privacypolicy: "Informativa sulla privacy",
        faq: "Domande frequenti",
        allrights: "Tutti i diritti riservati",
        breakfast_t: "COLAZIONE",
        groupoffers_t: "OFFERTE DI GRUPPO",
        parking_t: "PARCHEGGIO",
        promotions_t: "PROMOZIONI",
        services_w: "Scopri i nostri servizi esclusivi",
        promotions:
          "Presso la nostra struttura offriamo una varietà di buoni e promozioni che soddisfano le tue esigenze. Hai l'opportunità di risparmiare denaro e goderti i nostri servizi a seconda della stagione.",
        parking:
          "Trovare un parcheggio a Vienna è molto tedioso e costoso. Grazie alla nostra posizione, c'è un garage di fronte alla pensione. I biglietti per il parcheggio possono essere acquistati presso la reception a partire da soli 18 € al giorno.",
        groupoffers:
          "Che tu stia viaggiando con un gruppo scolastico, una delegazione professionale o per un matrimonio, abbiamo pacchetti personalizzati per gruppi per soddisfare le tue esigenze. Contattaci per scoprire di più sulle nostre offerte su misura per i gruppi.",
        breakfast:
          "Inizia la tua giornata con una deliziosa e nutriente colazione, preparata fresca ogni giorno. Serviamo un buffet nella nostra sala colazioni dalle 7:30 alle 10:00. Se preferisci una 'colazione da portare via', il nostro personale sarà lieto di preparartela.",
        submit_1: "Grazie per aver prenotato presso il nostro hotel",
        submit_2: "Verrai reindirizzato alla pagina principale tra poco",
        pagenotfound: "La pagina che stai cercando non esiste.",
        goback: "Torna indietro",
        err_subject: "Oggetto troppo lungo",
        err_message: "Messaggio troppo lungo",
        contact_w: "Contattaci per prenotare",
        con_email: "Indirizzo email",
        con_fax: "Numero di fax",
        con_location: "Posizione",
        con_send: "Invia messaggio",
        con_send_w:
          "Siamo entusiasti di darti il benvenuto nel nostro hotel! Contattaci per saperne di più sulle nostre camere e prenotazioni.",
        con_name: "Nome",
        con_subject: "Oggetto",
        con_message: "Messaggio",
        discover_w1: "ALZARE IL COMFORT AL LIVELLO PIÙ ALTO",
        discover_w2: "Scopri l'hotel",
        discover_p:
          "La nostra pensione si trova in una tipica casa in stile Wilhelminiano sulla Mariahilferstrasse. La pensione si estende su 3 piani e offre 30 camere e 2 appartamenti con diverse categorie di camere e prezzi. Ogni una delle nostre camere è diversa ma arredata con attenzione ai dettagli. Materassi di alta qualità e mobili individuali ci identificano.",
        discover_p2:
          "Rimanete nella nostra sala colazione e gustate una ricca colazione a buffet prima di partire per esplorare la città dalla nostra favolosa posizione.",
        photogallery: "Galleria fotografica",
        findus_title1: "Palazzo Schönbrunn",
        findus_title2: "Il Palazzo Imperiale di Hofburg",
        findus_title3: "Palazzo del Belvedere",
        findus_title4: "Cattedrale di Santo Stefano",
        findus_title5: "Teatro dell'Opera di Vienna",
        findus_desc1:
          "Il Palazzo Schönbrunn è un palazzo barocco a Vienna, Austria, costruito come residenza estiva per gli Asburgo. Dispone di oltre 1.400 stanze, arredi lussuosi e decorazioni intricate che mostrano l'opulenza degli Asburgo. I giardini del palazzo presentano prati, aiuole fiorite, fontane e punti di riferimento come la Fontana di Nettuno e la Gloriette. È una popolare attrazione turistica e un sito del patrimonio mondiale dell'UNESCO.",
        findus_desc2:
          "Il Palazzo di Hofburg è un grande complesso di palazzi nel centro di Vienna, Austria. Ha servito come residenza della dinastia degli Asburgo e del governo austriaco per oltre 700 anni. Il palazzo presenta impressionanti architetture barocche e gotiche ed è sede di diversi musei, tra cui gli appartamenti imperiali, il Museo Sisi e la Biblioteca Nazionale Austriaca. Oggi, è una delle attrazioni turistiche più popolari di Vienna, offrendo uno sguardo sulla ricca storia e cultura dell'Austria.",
        findus_desc3:
          "Il Palazzo del Belvedere è un complesso di edifici in stile barocco a Vienna, Austria, costruito come residenza estiva per il principe Eugenio di Savoia nel XVIII secolo. È composto dai palazzi di Belvedere superiore e inferiore, collegati da un bellissimo giardino. Oggi, è una popolare attrazione turistica e un museo d'arte, che espone opere di famosi artisti austriaci come Gustav Klimt ed Egon Schiele.",
        findus_desc4:
          "La Cattedrale di Santo Stefano, o Stephansdom, è una cattedrale in stile gotico a Vienna, Austria. La sua iconica guglia si erge a 136 metri di altezza e vanta finestre con vetrate artistiche intricate, soffitti a volta e altari ornati. La cattedrale ha una ricca storia come punto di riferimento religioso e culturale e rimane una popolare destinazione per i visitatori oggi.",
        findus_desc5:
          "Il Teatro dell'Opera di Vienna è un famoso teatro d'opera situato a Vienna, in Austria. È stato inaugurato nel 1869 ed è la sede dell'Opera di Stato di Vienna e dell'Orchestra Filarmonica di Vienna. L'edificio in stile neorinascimentale ha una capacità di oltre 2.200 posti a sedere ed è noto per la sua architettura ornamentale.",
        findus_arr1: "S-Bahn (S7)",
        findus_arr2: "CAT - City Airport Train",
        findus_arr3: "Vienna Airport Lines (Postbus ÖBB)",
        findus_arr4: "TAXI",
        findus_arrd1:
          "La S-Bahn offre il biglietto più economico per l'aeroporto. Diversi stop consentono un facile ingresso e uscita in tutta la città, la maggior parte con collegamenti suburbani e metropolitani. I treni partono dalle ore 04.00 alle ore 00.00. Il viaggio dura circa 23 minuti da Wien Mitte e costa 4,10 €",
        findus_arrd2:
          "Il City Airport Train è forse l'opzione di viaggio più comoda, ma anche una delle più costose. Ogni 30 minuti c'è un treno rapido tra Wien-Mitte e l'aeroporto. Da / a: Wien-Mitte (U3, U4 + S) - Flughafen Wien, Bahnhof Costo: acquistando online 11 € (solo andata), 19 € (andata e ritorno); acquistando alle macchine 12 € (solo andata), 21 € (andata e ritorno) Intervallo: 30 minuti",
        findus_arrd3:
          "Le Vienna Airport Lines collegano importanti snodi di trasporto con l'aeroporto. La maggior parte delle fermate degli autobus sono direttamente collegate alle stazioni della metropolitana. Gli autobus da Morzinplatz / Schwedenplatz e Westbahnhof partono ogni 30 minuti. Costo: 8 € (solo andata), 13 € (andata e ritorno) Intervallo: 30 minuti o 1 ora (linea VAL3) Durata: 20-40 minuti",
        findus_arrd4:
          "I centri taxi di Vienna offrono viaggi con un pacchetto aeroportuale. Per un prezzo fisso di 36 € (se ordinato in anticipo) si arriva direttamente da casa all'aeroporto. Accessibile ad esempio sotto 31300, 40100 o 60160. Si prega di dire al telefono che si ha bisogno di un taxi per l'aeroporto! Da / a: ovunque a Vienna - Aeroporto, partenza / arrivo Costo: prezzo fisso di 36 € (+ mancia) Durata: varia a seconda del luogo di partenza e del traffico Durata: 20-40 minuti",
        arrival: "Arrivo",
        find_location_desc: `La nostra casa è un punto di partenza ideale per scoprire la bellezza di Vienna. Grazie alla nostra posizione centrale, tutte le attrazioni sono facilmente raggiungibili: siamo a soli 10 minuti a piedi dalla stazione ferroviaria "Westbahnhof" e a 20 minuti a piedi dal centro città. Il nostro indirizzo è Andreasgasse 1, 1070 Vienna, vicino alla famosa via dello shopping "Mariahilfer Straße". Con la stazione della metropolitana "U3 Zieglergasse" proprio di fronte alla nostra casa, è possibile raggiungere le attrazioni culturali o lo shopping in pochi minuti.`,
        bookroom: "Prenota la tua stanza",
        searchroom: "Cerca stanze",
        roomtype: "tipo di stanza",
        guests: "ospiti",
        roomprice: "prezzo della stanza",
        breakfast_r: "colazione",
        pets: "animali domestici",
        learnmore: "Approfondisci",
        workspace: "Spazio di lavoro",
        minibar: "Minibar",
        towels: "Asciugamani",
        shower: "Doccia",
        tv: "TV",
        wifi: "WI-Fi",
        size: "Dimensione",
        sqm: "m²",
        occupancy: "Occupazione",
        no_rooms:
          "Purtroppo nessuna stanza corrisponde ai parametri di ricerca.",
        price: "PREZZO",
        booknow: "PRENOTA ORA",
        freewifi: "WI-FI GRATUITO",
        amenities: "Servizi",
        oldprice: "VECCHIO PREZZO",
        newprice: "NUOVO PREZZO",
        pricing: "Prezzi",
        description: "Descrizione",
        beds2: "LETTO",
        floors: "PIANI",
        guests2: "OSPITI",
        squarem2: "METRI QUADRATI",
        inttv: "CANALI TV INTERNAZIONALI",
        showerava: "DOCCIA DISPONIBILE",
        minibarava: "MINIBAR DISPONIBILE",
        deskava: "SCRIVANIA DISPONIBILE",
        breakava: "COLAZIONE DISPONIBILE",
        petsava: "ANIMALI DOMESTICI AMMESSI",
        err_special: "Messaggio di richiesta speciale troppo lungo",
        guestdetails: "Dettagli degli ospiti",
        country: "Paese",
        firstname: "Nome",
        lastname: "Cognome",
        completing:
          "Concludendo questa prenotazione riconosco di aver letto e accettato i",
        continue: "Continua al check-out",
        specialrequest: "Richiesta speciale",
        order: "Riepilogo dell'ordine",
        beds: "Letti",
        guest: "Ospiti",
        subtotal: "Subtotale",
        tax: "Imposta",
        total: "Totale",
        save_changes: "Salva modifiche",
        main_head: "Richiesta di Manutenzione",
        send_request: "Invia Richiesta",
        submit_main: "Invio della Richiesta di Manutenzione",
        submit_main1: "Richiesta di Manutenzione Inviata",
        feedback_submit1:
          "Grazie per aver dedicato del tempo a condividere il tuo feedback con noi!",
        feedback_submit2:
          "Apprezziamo il tuo contributo e lo terremo in considerazione mentre lavoriamo per migliorare i nostri servizi.",
        feedback_head: "Feedback",
        feedback_w:
          "Saremmo entusiasti se poteste condividere i vostri pensieri sul nostro sito web. Il vostro feedback è incredibilmente prezioso per noi e ci aiuta a migliorare i nostri servizi.",
      },
    },
  },
});

export default i18n;
